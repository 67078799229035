const toasterror = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    pauseOnFocusLoss: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: false,
    theme: "colored",
  };

module.exports = { toasterror }

