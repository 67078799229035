const toastsuccess = {
  position: "top-right",
  autoClose: 1500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: false,
  theme: "colored",
};

module.exports = { toastsuccess };
